/* @import "~highlight.js/styles/googlecode.css"; */
@import "~perfect-scrollbar/css/perfect-scrollbar.css";
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url("https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css");
@import url("//cdn.quilljs.com/1.2.6/quill.snow.css");


@import 'material-icons/iconfont/material-icons.css';

@font-face {
  font-family: "VideoJS";
  src: url("https://vjs.zencdn.net/f/1/vjs.eot");
  src: url("https://vjs.zencdn.net/f/1/vjs.eot?#iefix")
      format("embedded-opentype"),
    url("https://vjs.zencdn.net/f/1/vjs.woff") format("woff"),
    url("https://vjs.zencdn.net/f/1/vjs.ttf") format("truetype");
}

button {
  border: none;
  outline: none;
  background-color: transparent;
}

* {
  font-family: 'Lato', sans-serif !important;
  margin: 0;
  padding: 0;
  scrollbar-width: thin !important;
  text-decoration: none;
}

@media (max-width: 768px) {
  * {
    font-size: 13px;
  }
}

@media (max-width: 425px) {
  * {
    font-size: 12px;
    
  }
}

html,
body {
  background-color: #f9f9f9 !important;
  color: #222222;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  /* display: grid; */
  /* grid-template-rows: 1fr 2fr 1fr; */
  overflow-x: hidden;
}

.text-danger {
  font-size: 0.7em;
}

/* CHROME SCROLLBAR */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background: rgb(200, 200, 200);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(170, 170, 170);
}

.MuiButton-root {
  text-transform: none !important;
}

h1,
h2,
p,
span {
  margin: 0;
}

a {
  color: #591AB6;
}

/* .crisp-client {
  position: absolute !important;
  bottom: 0 !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  transform: translateY(-50%) !important;
  padding-bottom: 10rem !important;
  height: 100% !important;
} */

.video-js .vjs-play-control.vjs-playing .vjs-icon-placeholder:before,
.vjs-icon-pause:before {
  display: flex;
  justify-content: center;
  align-items: center;
  content: "\f103";
  font-family: "VideoJS";
}

.video-js .vjs-mute-control .vjs-icon-placeholder:before,
.vjs-icon-volume-high:before {
  display: flex;
  justify-content: center;
  align-items: center;
  content: "\f107";
  font-family: "VideoJS";
}

.video-js .vjs-big-play-button .vjs-icon-placeholder:before,
.video-js .vjs-play-control .vjs-icon-placeholder:before,
.vjs-icon-play:before {
  display: flex;
  justify-content: center;
  align-items: center;
  content: "\f101";
  font-family: "VideoJS";
}

.video-js .vjs-picture-in-picture-control .vjs-icon-placeholder:before,
.vjs-icon-picture-in-picture-enter:before {
  display: flex;
  justify-content: center;
  align-items: center;
  content: "\f121";
  font-family: "VideoJS";
}

.video-js .vjs-fullscreen-control .vjs-icon-placeholder:before,
.vjs-icon-fullscreen-enter:before {
  display: flex;
  justify-content: center;
  align-items: center;
  content: "\f108";
  font-family: "VideoJS";
}

body > iframe[style*="2147483647"]:not([id="webpack-dev-server-client-overlay"]) { display: none; }